import { OrganizationInterface, ReducerActionInterface } from '../../interfaces';
import { OrganizationConstants } from './organizations.constants';

// ============ INTERFACE ============

export interface OrganizationStateInterface {
  isLoading: boolean;
  data: Array<OrganizationInterface>;
}

// ============ INIT STATE ============

const initialState: OrganizationStateInterface = {
  isLoading: false,
  data: []
};

// ============ REDUCERS ============

const setOrganization = (state: OrganizationStateInterface, payload: Array<OrganizationInterface>): OrganizationStateInterface => {
  return {
    ...state,
    isLoading: false,
    data: payload
  };
};

const setLoading = (state: OrganizationStateInterface, payload: boolean): OrganizationStateInterface => {
  return {
    ...state,
    isLoading: payload
  };
};

// ============ EXPORTS ============

export const OrganizationReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case OrganizationConstants.LOADING:
      return setLoading(state, action.payload);
    case OrganizationConstants.SET:
      return setOrganization(state, action.payload);
    default:
      return state;
  }
};
