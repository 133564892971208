import React from 'react';
import { Button, Descriptions } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { i18n, logoutAction, StoreStateInterface } from '../../../common';

function DashboardPageComponent() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  function onLogOutBtnClick() {
    dispatch(logoutAction());
  }

  return (
    <div className="w100-h100">
      <Descriptions title={i18n.translate('dashboard.userDetails.title')}>
        <Descriptions.Item label={i18n.translate('dashboard.userDetails.name')}>{userAuth.user?.name}</Descriptions.Item>
        <Descriptions.Item label={i18n.translate('dashboard.userDetails.email')}>{userAuth.user?.email}</Descriptions.Item>
        <Descriptions.Item label={i18n.translate('dashboard.userDetails.role')}>{userAuth.user?.role?.name}</Descriptions.Item>
        <Descriptions.Item label={i18n.translate('dashboard.userDetails.organization')}>{userAuth.user?.organization?.name}</Descriptions.Item>
        <Descriptions.Item label={i18n.translate('dashboard.userDetails.organizationCode')}>{userAuth.user?.organization?.uuid}</Descriptions.Item>
      </Descriptions>

      <div className="center-center">
        <Button onClick={onLogOutBtnClick}>{i18n.translate('dashboard.logoutBtn')}</Button>
      </div>
    </div>
  );
}

export default DashboardPageComponent;
