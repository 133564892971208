import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Form, Input, Button, Select, DatePicker, Switch, notification } from 'antd';
import { getPrintersAction, loadSoftwareReleaseAction, NavigationService, PrinterConstants, PrinterInterface, PrinterService, PrinterStateInterface, PrinterStatusEnumList, PrinterStatusEnumTranslate, SoftwareReleaseStateInterface, StoreStateInterface } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { getOrganizationsAction, OrganizationStateInterface } from '../../../common/redux/organizations';

const { Option } = Select;

interface ParamTypes {
  id: string;
}

function PrinterEditPageComponent() {
  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState<PrinterInterface | undefined>();

  const printerStore: PrinterStateInterface = useSelector((state: StoreStateInterface) => state.printers) as PrinterStateInterface;
  const softwareReleaseStore: SoftwareReleaseStateInterface = useSelector((state: StoreStateInterface) => state.softwareReleases) as SoftwareReleaseStateInterface;
  const organizationStore: OrganizationStateInterface = useSelector((state: StoreStateInterface) => state.organizations) as OrganizationStateInterface;

  useEffect(() => {
    dispatch(getPrintersAction());
    dispatch(loadSoftwareReleaseAction());
    dispatch(getOrganizationsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id === 'new') return;
    if (printerStore.isLoading) return;
    let _id = parseInt(id);
    let printer = printerStore.data.find(x => x.id === _id);
    if (!printer) return;
    modellToForm(printer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printerStore]);

  const onFinish = (formValues: any) => {
    setIsLoading(true);
    let printer: PrinterInterface = formToModel(formValues);
    PrinterService.post(printer).subscribe((response) => {
      if (!response.success) {
        notification['error']({ message: response.error, duration: 2 });
        setIsLoading(false);
        return;
      }

      dispatch({ type: PrinterConstants.SET, payload: response.data });
      NavigationService.navigate(AppRoutes.PRINTER.fullPath);
    });
  };

  function modellToForm(printer: PrinterInterface): void {
    setModel(printer);
    form.setFieldsValue({
      name: printer.name,
      location: printer.location,
      serial_number: printer.serial_number,
      release_date: moment(printer.release_date),
      current_version_id: printer.current_version_id,
      note: printer.note,
      organization_id: printer.organization_id,
      printer_status_id: printer.status!.id,
      is_active: printer.is_active ? 1 : 0,
    });
  }

  function formToModel(formValues: any): PrinterInterface {
    let printer: PrinterInterface = {
      name: formValues.name,
      location: formValues.location,
      note: formValues.note,
      printer_status_id: formValues.printer_status_id,
      organization_id: formValues.organization_id,
      is_active: formValues.is_active,
    } as any;

    if (model) {
      printer.id = model.id;
    } else {
      printer = {
        ...printer,
        serial_number: formValues.serial_number,
        release_date: formValues.release_date.format('YYYY-MM-DD'),
        current_version_instaledd_at: formValues.release_date.format('YYYY-MM-DD'),
        current_version_id: formValues.current_version_id,
        available_version_id: formValues.current_version_id,
      }
    }

    return printer;
  }

  return (
    <div className="printer w100-h100">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ is_active: true }}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        autoComplete="off"
      >

        <Form.Item label="Naziv" name="name" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Lokacija" name="location" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Serijski broj" name="serial_number" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Input disabled={!!model} />
        </Form.Item>

        <Form.Item label="Datum izrade" name="release_date" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <DatePicker disabled={!!model} />
        </Form.Item>

        <Form.Item label="Verzija softwera" name="current_version_id" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Select placeholder="Select a option" disabled={!!model} >
            { softwareReleaseStore.data.filter(x => x.is_active).map(x => (<Option key={x.id} value={x.id}>{x.version}</Option>)) }
          </Select>
        </Form.Item>

        <Form.Item label="Printer status" name="printer_status_id" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Select placeholder="Select a option">
            { PrinterStatusEnumList.map(x => (<Option key={x} value={x}>{PrinterStatusEnumTranslate(x)}</Option>)) }
          </Select>
        </Form.Item>

        <Form.Item label="Bilješka" name="note">
          <TextArea />
        </Form.Item>

        <Form.Item label="Organizacija" name="organization_id">
          <Select placeholder="Select a option" allowClear>
            { organizationStore.data.map(x => (<Option key={x.id} value={x.id!}>{x.name}</Option>)) }
          </Select>
        </Form.Item>

        <Form.Item label="Aktivan" name="is_active" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}> Spremi </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default PrinterEditPageComponent;
