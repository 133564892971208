import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Form, Input, Button, Select, Switch, PageHeader, Table, Drawer } from 'antd';
import { PlusOutlined, DeleteRowOutlined } from '@ant-design/icons';

import { AppRoutes } from '../_router/app.routes';
import { i18n, NavigationService, OrganizationInterface, OrganizationService, StoreStateInterface, UserInterface } from '../../../common';
import { OrganizationStateInterface, getOrganizationsAction, OrganizationConstants } from '../../../common/redux/organizations';
import UserFormComponent from '../../../common/components/userForm/userForm.component';

interface ParamTypes {
  id: string;
}

function OrganizationEditPageComponent() {
  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState<OrganizationInterface | undefined>();
  
  const organizationStore: OrganizationStateInterface = useSelector((state: StoreStateInterface) => state.organizations) as OrganizationStateInterface;

  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const [userDrawerData, setUserDrawerData] = useState<UserInterface | undefined>();

  useEffect(() => {
    dispatch(getOrganizationsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id === 'new') return;
    if (organizationStore.isLoading) return;
    let _id = parseInt(id);
    let organization = organizationStore.data.find(x => x.id === _id);
    if (!organization) return;
    modellToForm(organization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationStore]);

  const onFinish = (formValues: any) => {
    setIsLoading(true);
    let organization: OrganizationInterface = formToModel(formValues);
    OrganizationService.post(organization).subscribe((response) => {
      dispatch({ type: OrganizationConstants.SET, payload: response.data });
      NavigationService.navigate(AppRoutes.ORGANIZATIONS.fullPath);
    });
  };

  function modellToForm(data: OrganizationInterface): void {
    setModel(data);
    form.setFieldsValue({
      name: data.name,
      is_active: data.is_active,
      print_offer: data.print_offer,
      print_request: data.print_request,
    });
  }

  function formToModel(formValues: any): OrganizationInterface {
    let data: OrganizationInterface = {
      name: formValues.name,
      is_active: formValues.is_active,
      print_offer: formValues.print_offer,
      print_request: formValues.print_request,
    };

    if (model) {
      data.id = model.id;
    }

    return data;
  }

  // === RENDER ===

  function OrganizationUsers(): any {
    if (!model) return null;

    const columns = [
      {
        title: i18n.translate('organization.table.id'),
        render: (user: UserInterface) => <a onClick={() => { setUserDrawerData(user); }}>{user.id}</a>,
      },
      {
        title: i18n.translate('organization.table.user.name'),
        render: (user: UserInterface) => `${user.name} ${user.is_active ? '' : '['+i18n.translate('organization.msgs.unactive')+']'}`
      },
      {
        title: i18n.translate('organization.table.user.email'),
        render: (user: UserInterface) => `${user.email}`
      },
      {
        title: i18n.translate('organization.table.user.role'),
        render: (user: UserInterface) => user.role!.name
      },
    ];

    function onUserChanged(replace: boolean, user: UserInterface) {
      if (!model || !model.users) return null;
      let users = [...model.users];
      if(replace) users[users.findIndex(x => x.id === user.id)] = user;
      else users.push(user);
      setModel({
        ...model,
        users
      });
      setUserDrawerData(undefined);
    }

    return (
      <>
        <PageHeader
        className="site-page-header"
        title="Korisnici"
        backIcon={false}
        />
        {/* BUTTONS */}
        <div className="flex-row space-between">
          <Button onClick={() => { setUserDrawerData({} as any); }} type="primary" shape="round" className='mb8' icon={<PlusOutlined />}>
            {i18n.translate('common.btns.add')}
          </Button>
          <Button onClick={() => setShowInactiveUsers(!showInactiveUsers)} type={showInactiveUsers ? 'primary' : 'default'} shape="round" icon={<DeleteRowOutlined />} />
        </div>

        {/* TABLE */}
        <Table loading={organizationStore.isLoading} columns={columns} dataSource={ model.users!.filter(x => (showInactiveUsers ? !x.is_active : x.is_active)) } rowKey={(row) => row.id!} pagination={{ hideOnSinglePage: true }} />

        {/* DRAWER */}
        <Drawer title="Printer events" placement="right" width={'50%'} onClose={() => setUserDrawerData(undefined)} visible={!!userDrawerData}>
          { userDrawerData && (<UserFormComponent user={userDrawerData} organizationId={model.id} onClose={(replace: boolean, user: UserInterface) => onUserChanged(replace, user) } />) }
        </Drawer>
      </>
    );
  }

  return (
    <div className="organization w100-h100">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ is_active: 1 }}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        
        <Form.Item label="Naziv" name="name" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Aktivna" name="is_active"  valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item label="Davati print" name="print_offer"  valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item label="Zahtjevati print" name="print_request"  valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}> Spremi </Button>
        </Form.Item>
      </Form>

      <OrganizationUsers />
    </div>
  );
}

export default OrganizationEditPageComponent;
