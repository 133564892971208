import React, { useEffect, useState } from 'react';
import { Table, Button, Radio } from 'antd';
import { DeleteRowOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { i18n, loadSoftwareReleaseAction, SoftwareReleaseInterface, SoftwareReleaseService, SoftwareReleaseStateInterface, StoreStateInterface } from '../../../common';

function SoftwareReleasePageComponent() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const softwareReleaseStore: SoftwareReleaseStateInterface = useSelector((state: StoreStateInterface) => state.softwareReleases) as SoftwareReleaseStateInterface;

  useEffect(() => {
    dispatch(loadSoftwareReleaseAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(softwareReleaseStore);
  }, [softwareReleaseStore]);

  const [active, setActive] = useState<'active' | 'inactive'>('active');

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'VERSION',
      dataIndex: 'version',
      key: 'version'
    },
    {
      title: 'DATE',
      render: (release: SoftwareReleaseInterface) => (
        <>
          {moment(release.date).format('DD.MM.YYYY. HH:mm ')}
        </>
      ),
    },
    {
      title: '',
      render: (release: SoftwareReleaseInterface) => (
        <div className='center-center'>
          <Button onClick={() => changeState(release)} loading={isLoading} type="primary" shape="circle" icon={<DeleteRowOutlined />} />
        </div>
      ),
    },
  ];

  function getData() {
    return softwareReleaseStore.data.filter(x => active === 'active' ? x.is_active : !x.is_active);
  }

  function changeState(release: SoftwareReleaseInterface) {
    setIsLoading(true);
    release.is_active = !release.is_active;
    SoftwareReleaseService.post(release).subscribe((response) => {
      dispatch(loadSoftwareReleaseAction());
      setIsLoading(false);
    });
  }

  return (
    <div className="printer w100-h100">
      <div className="flex-row space-between mb8">
        <div></div>
        <Radio.Group value={active} onChange={e => setActive(e.target.value)}>
          <Radio.Button value="active">{i18n.translate('common.activeInactive.active')}</Radio.Button>
          <Radio.Button value="false">{i18n.translate('common.activeInactive.inactive')}</Radio.Button>
        </Radio.Group>
      </div>
      <Table loading={softwareReleaseStore.isLoading} columns={columns} dataSource={getData()} rowKey={(row) => row.id} pagination={{ hideOnSinglePage: true }} />
    </div>
  );
}

export default SoftwareReleasePageComponent;
