import HttpFactory from './abstract.api.service';
import { apiRoutes } from './routes';
import { Observable } from 'rxjs';
import { BaseResponseInterface, JobInterface, JobStatusLog, LoginInterface, LoginResponseInterface, NotificationInterface, OrganizationInterface, PrinterEventInterface, PrinterInterface, SoftwareReleaseInterface, UserInterface } from '../interfaces';
import { JobStatusEnum } from '../enums';

export class UserService {
  static login(data: LoginInterface): Observable<BaseResponseInterface<LoginResponseInterface>> {
    return HttpFactory.POST(apiRoutes.login, { email: data.emailOrNickname, password: data.password });
  }

  static getCurrentUser(): Observable<BaseResponseInterface<UserInterface>> {
    return HttpFactory.GET(apiRoutes.currentUser);
  }

  static post(user: UserInterface): Observable<BaseResponseInterface<any>> {
    return HttpFactory.POST(apiRoutes.user, user);
  }
}

export class PrinterService {
  static getAll(): Observable<BaseResponseInterface<PrinterInterface>> {
    return HttpFactory.GET(apiRoutes.printer);
  }

  static post(printer: PrinterInterface): Observable<BaseResponseInterface<PrinterInterface>> {
    return HttpFactory.POST(apiRoutes.printer, printer);
  }

  static getApiKey(id: number): Observable<BaseResponseInterface<string>> {
    return HttpFactory.GET(apiRoutes.printerApiKey + '/' + id);
  }
}

export class SoftwareReleaseService {
  static getAll(): Observable<BaseResponseInterface<SoftwareReleaseInterface>> {
    return HttpFactory.GET(apiRoutes.release);
  }

  static post(release: SoftwareReleaseInterface): Observable<BaseResponseInterface<SoftwareReleaseInterface>> {
    return HttpFactory.POST(apiRoutes.release, release);
  }
}

export class PrinterEventService {
  static getPrinterEvents(id: number): Observable<BaseResponseInterface<Array<PrinterEventInterface>>> {
    return HttpFactory.GET(apiRoutes.event + '/' + id);
  }
}

export class OrganizationService {
  static getAll(): Observable<BaseResponseInterface<Array<OrganizationInterface>>> {
    return HttpFactory.GET(apiRoutes.organization);
  }

  static post(organization: OrganizationInterface): Observable<BaseResponseInterface<OrganizationInterface>> {
    return HttpFactory.POST(apiRoutes.organization, organization);
  }

  static postAddPartner(organization_id: number, partner_uuid: string ): Observable<BaseResponseInterface<any>> {
    return HttpFactory.POST(apiRoutes.organizationAddPartner, { organization_id, partner_uuid  });
  }

  static removePartner(organization_id: number, partner_organization_id: number ): Observable<BaseResponseInterface<any>> {
    return HttpFactory.POST(apiRoutes.organizationRemovePartner, { organization_id, partner_organization_id  });
  }

  static postAddClient(organization_id: number, partner_uuid: string ): Observable<BaseResponseInterface<any>> {
    return HttpFactory.POST(apiRoutes.organizationAddClient, { organization_id, partner_uuid  });
  }

  static removeClient(organization_id: number, partner_organization_id: number ): Observable<BaseResponseInterface<any>> {
    return HttpFactory.POST(apiRoutes.organizationRemoveClient, { organization_id, partner_organization_id  });
  }

  static getApiKey(): Observable<BaseResponseInterface<string>> {
    return HttpFactory.GET(apiRoutes.organizationApiKey);
  }

}

export class NotificationService {
  static getAll(): Observable<BaseResponseInterface<Array<NotificationInterface>>> {
    return HttpFactory.GET(apiRoutes.notifications);
  }

  static getCount(): Observable<BaseResponseInterface<number>> {
    return HttpFactory.GET(apiRoutes.notifications + '/count');
  }

  static readAll(): Observable<BaseResponseInterface<NotificationInterface>> {
    let payload = { "all": true };
    return HttpFactory.POST(apiRoutes.notifications, payload);
  }

  static read(ids: Array<number>): Observable<BaseResponseInterface<NotificationInterface>> {
    let payload = { ids };
    return HttpFactory.POST(apiRoutes.notifications, payload);
  }

}

export class JobsService {
  static getAll(): Observable<BaseResponseInterface<Array<JobInterface>>> {
    return HttpFactory.GET(apiRoutes.jobs);
  }

  static post(job: JobInterface): Observable<BaseResponseInterface<JobInterface>> {
    return HttpFactory.POST(apiRoutes.jobs, job);
  }

  static removeFile(id: number): Observable<BaseResponseInterface<boolean>> {
    let payload = { id };
    return HttpFactory.POST(apiRoutes.jobsRemoveFile, payload);
  }

  static statusUpdate(id: number, job_status_id: JobStatusEnum): Observable<BaseResponseInterface<boolean>> {
    let payload = { id, job_status_id };
    return HttpFactory.POST(apiRoutes.jobsStatusUpdate, payload);
  }

  static getHistory(id: number): Observable<BaseResponseInterface<Array<JobStatusLog>>> {
    return HttpFactory.GET(apiRoutes.jobs + '/' + id + '/history');
  }

}

export class DmsService {

  static getTempUrl(uuid: string): Observable<{data: { url: string; }}> {
    return HttpFactory.GET('dms/get-temp-url/' + uuid);
  }

}
