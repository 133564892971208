import { Dispatch } from 'redux';
import { BaseResponseInterface, SoftwareReleaseInterface } from '../../interfaces';
import { SoftwareReleaseService } from '../../api/api.service';
import { SoftwareReleaseConstants } from './softwareRelease.constants';

export function loadSoftwareReleaseAction() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SoftwareReleaseConstants.LOADING,
      payload: true,
    });
    return SoftwareReleaseService.getAll().subscribe(
      (response: BaseResponseInterface<SoftwareReleaseInterface>) => {
        dispatch({
          type: SoftwareReleaseConstants.SET,
          payload: response.data,
        });
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}
