import { i18n } from "../services";

export enum JobStatusEnum {
    CREATED = 1,
    RECEIVED = 2,
    ACCEPTED = 3,
    PROCESSING = 4,
    PRINTED = 5,
    SENT = 6,
    CANCELED = 7,
}

export const JobStatusEnumList = [
    JobStatusEnum.CREATED,
    JobStatusEnum.RECEIVED,
    JobStatusEnum.ACCEPTED,
    JobStatusEnum.PROCESSING,
    JobStatusEnum.PRINTED,
    JobStatusEnum.SENT,
    JobStatusEnum.CANCELED,
];

export const JobStatusEnumTranslate = (x: JobStatusEnum) => i18n.translate(`enums.jobStatus.${x}`);

export const JobStatusActionEnumTranslate = (x: JobStatusEnum) => i18n.translate(`enums.jobStatusAction.${x}`);
