export const apiRoutes: any = {
  login: `auth/login`,
  printer: `printers`,
  printerApiKey: `printers/api-key`,
  release: `software-release`,
  event: `event`,
  organization: `organizations`,
  organizationAddPartner: `organizations/add-partner`,
  organizationRemovePartner: `organizations/remove-partner`,
  organizationAddClient: `organizations/add-client`,
  organizationRemoveClient: `organizations/remove-client`,
  organizationApiKey: `organizations/api-key/fetch`,
  user: `users`,
  currentUser: `user`,
  notifications: `notifications`,
  jobs: `jobs`,
  jobsRemoveFile: `jobs/remove-file`,
  jobsStatusUpdate: `jobs/status-update`,
};
