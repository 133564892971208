import { JobInterface, ReducerActionInterface } from '../../interfaces';
import { JobConstants } from './jobs.constants';

// ============ INTERFACE ============

export interface JobStateInterface {
  isLoading: boolean;
  data: Array<JobInterface>;
}

// ============ INIT STATE ============

const initialState: JobStateInterface = {
  isLoading: false,
  data: []
};

// ============ REDUCERS ============

const setJob = (state: JobStateInterface, payload: Array<JobInterface>): JobStateInterface => {
  return {
    ...state,
    isLoading: false,
    data: payload
  };
};

const setLoading = (state: JobStateInterface, payload: boolean): JobStateInterface => {
  return {
    ...state,
    isLoading: payload
  };
};

// ============ EXPORTS ============

export const JobReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case JobConstants.LOADING:
      return setLoading(state, action.payload);
    case JobConstants.SET:
      return setJob(state, action.payload);
    default:
      return state;
  }
};
