import { PrinterConstants } from './printer.constants';
import { ReducerActionInterface } from '../../interfaces';
import { PrinterInterface } from '../../interfaces/common/printer.interface';

// ============ INTERFACE ============

export interface PrinterStateInterface {
  isLoading: boolean;
  data: Array<PrinterInterface>;
}

// ============ INIT STATE ============

const initialState: PrinterStateInterface = {
  isLoading: false,
  data: []
};

// ============ REDUCERS ============

const setPrinter = (state: PrinterStateInterface, payload: Array<PrinterInterface>): PrinterStateInterface => {
  return {
    ...state,
    isLoading: false,
    data: payload
  };
};

const setLoading = (state: PrinterStateInterface, payload: boolean): PrinterStateInterface => {
  return {
    ...state,
    isLoading: payload
  };
};

// ============ EXPORTS ============

export const PrinterReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case PrinterConstants.LOADING:
      return setLoading(state, action.payload);
    case PrinterConstants.SET:
      return setPrinter(state, action.payload);
    default:
      return state;
  }
};
