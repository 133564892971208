import { SoftwareReleaseConstants } from './softwareRelease.constants';
import { ReducerActionInterface } from '../../interfaces';
import { SoftwareReleaseInterface } from '../../interfaces/common/softwareRelease.interface';

// ============ INTERFACE ============

export interface SoftwareReleaseStateInterface {
  isLoading: boolean;
  data: Array<SoftwareReleaseInterface>;
}

// ============ INIT STATE ============

const initialState: SoftwareReleaseStateInterface = {
  isLoading: false,
  data: []
};

// ============ REDUCERS ============

const setsoftwareRelease = (state: SoftwareReleaseStateInterface, payload: Array<SoftwareReleaseInterface>): SoftwareReleaseStateInterface => {
  return {
    ...state,
    isLoading: false,
    data: payload
  };
};

const setLoading = (state: SoftwareReleaseStateInterface, payload: boolean): SoftwareReleaseStateInterface => {
  return {
    ...state,
    isLoading: payload
  };
};

// ============ EXPORTS ============

export const SoftwareReleaseReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case SoftwareReleaseConstants.LOADING:
      return setLoading(state, action.payload);
    case SoftwareReleaseConstants.SET:
      return setsoftwareRelease(state, action.payload);
    default:
      return state;
  }
};
