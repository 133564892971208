import React from 'react';
import { Layout } from 'antd';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DashboardOutlined, PrinterOutlined, UnorderedListOutlined, BellOutlined, ContainerOutlined, UserOutlined, GlobalOutlined, ContactsOutlined, DeploymentUnitOutlined, ApiOutlined } from '@ant-design/icons';
import { AppRoutes } from './app.routes';
import {
  ContentComponent,
  FooterComponent,
  HeaderComponent,
  MenuComponent,
  NotFoundPageComponent,
  StoreStateInterface,
  UserRoleEnum,
} from '../../../common';
import { 
  DashboardPageComponent, 
  PrinterEditPageComponent, 
  PrintersPageComponent, 
  SoftwareReleasePageComponent 
} from '..';
import OrganizationsPageComponent from '../organizations/organizations.component';
import OrganizationEditPageComponent from '../organizationEdit/organizationEdit.component';
import PartnersPageComponent from '../partners/partners.component';
import NotificationsPageComponent from '../notifications/notifications.component';
import JobsPageComponent from '../jobs/jobs.component';
import JobEditPageComponent from '../jobEdit/jobEdit.component';
import ApiOrganizationPageComponent from '../apiOrganization/apiOrganization.component';
import ApiPrinterPageComponent from '../apiPrinter/apiPrinter.component';
import JobViewPageComponent from '../jobView/jobView.component';

function AppRouter() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const { path } = useRouteMatch();
  const lang = useSelector((state: StoreStateInterface) => state.auth.lang);

  let routes = [
    {
      name: 'menu.dashboard', 
      icon: (<DashboardOutlined />),
      path: AppRoutes.DASHBOARD.path,
      component: DashboardPageComponent,
      exact: true,
      roles: [],
    },
    {
      name: 'menu.jobs',
      icon: (<ContainerOutlined />),
      path: AppRoutes.JOBS.path,
      component: JobsPageComponent,
      exact: true,
      roles: [],
    },
    {
      path: AppRoutes.JOBS.path + '/view/:id',
      component: JobViewPageComponent,
      exact: true,
      roles: [],
    },
    {
      path: AppRoutes.JOBS.path + '/edit/:id',
      component: JobEditPageComponent,
      exact: true,
      roles: [],
    },
    {
      name: 'menu.organizations',
      icon: (<GlobalOutlined />),
      path: AppRoutes.ORGANIZATIONS.path,
      component: OrganizationsPageComponent,
      exact: true,
      roles: [UserRoleEnum.SUPERADMIN],
    },
    {
      path: AppRoutes.ORGANIZATIONS.path + '/:id',
      component: OrganizationEditPageComponent,
      exact: true,
      roles: [UserRoleEnum.SUPERADMIN],
    },
    {
      name: 'menu.partners',
      icon: (<DeploymentUnitOutlined />),
      path: AppRoutes.PARTNERS.path,
      component: PartnersPageComponent,
      exact: true,
      roles: [],
      print_request: true
    },
    {
      name: 'menu.clients',
      icon: (<ContactsOutlined />),
      path: AppRoutes.CLIENTS.path,
      component: PartnersPageComponent,
      exact: true,
      roles: [],
      print_offer: true
    },
    {
      name: 'menu.printers',
      icon: (<PrinterOutlined />),
      path: AppRoutes.PRINTER.path,
      component: PrintersPageComponent,
      exact: true,
      roles: [],
      print_offer: true,
    },
    {
      name: 'menu.printers',
      icon: (<PrinterOutlined />),
      path: AppRoutes.PRINTER.path,
      component: PrintersPageComponent,
      exact: true,
      roles: [UserRoleEnum.SUPERADMIN],
    },
    {
      path: AppRoutes.PRINTER.path + '/:id',
      component: PrinterEditPageComponent,
      exact: true,
      roles: [UserRoleEnum.SUPERADMIN],
    },
    {
      name: 'menu.softwareReleases',
      icon: (<UnorderedListOutlined />),
      path: AppRoutes.SOFTWARE_RELEASE.path,
      component: SoftwareReleasePageComponent,
      exact: true,
      roles: [UserRoleEnum.SUPERADMIN],
    },
    {
      name: 'menu.notifications',
      icon: (<BellOutlined />),
      path: AppRoutes.NOTIFICATIONS.path,
      component: NotificationsPageComponent,
      exact: true,
      roles: [],
    },
    {
      name: 'menu.apiOrganization',
      icon: (<ApiOutlined />),
      path: AppRoutes.API_ORGANIZATION.path,
      component: ApiOrganizationPageComponent,
      exact: true,
      roles: [UserRoleEnum.ADMIN],
    },
    {
      name: 'menu.apiPrinter',
      icon: (<ApiOutlined />),
      path: AppRoutes.API_PRINTER.path,
      component: ApiPrinterPageComponent,
      exact: true,
      roles: [UserRoleEnum.SUPERADMIN],
    },
  ];

  const allowedRoutes = routes.filter((r) => {
    if (!userAuth.isAuthenticated || !userAuth.user) return false;
    
    if (r.print_request) { return userAuth.user.organization?.print_request; }
    if (r.print_offer) { return userAuth.user.organization?.print_offer; }

    if (r.roles.length === 0) return true;
    if ((r as any).roles.includes(userAuth.user.role_id)) return true;

    return false;
  });

  return (
    <Layout key={lang} className="layout">
      <HeaderComponent></HeaderComponent>
      <MenuComponent allRoutes={allowedRoutes}></MenuComponent>
      <ContentComponent className="content site-container mt32">
        <Switch>
          {allowedRoutes.map((route, index) => (
            <Route exact={route.exact} path={`${path}/${route.path}`} key={index}>
              {' '}
              <route.component />{' '}
            </Route>
          ))}
          <Redirect to="/" />
          {/* <Route component={NotFoundPageComponent} /> */}
        </Switch>
      </ContentComponent>
      <FooterComponent></FooterComponent>
    </Layout>
  );
}

export default AppRouter;
