import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Descriptions, Timeline } from 'antd';
import { EditOutlined, DownloadOutlined } from '@ant-design/icons';

import { DmsService, i18n, JobFileInterface, JobInterface, JobsService, JobStatusEnum, JobStatusLog, NavigationService, StoreStateInterface } from '../../../common';

import { getJobsAction, JobStateInterface } from '../../../common/redux/jobs';
import { AppRoutes } from '../_router/app.routes';

interface ParamTypes {
  id: string;
}

function JobViewPageComponent() {
  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const jobStore: JobStateInterface = useSelector((state: StoreStateInterface) => state.jobs) as JobStateInterface;

  const [model, setModel] = useState<JobInterface | undefined>();
  const [history, setHistory] = useState<Array<JobStatusLog>>([]);
  const [isLoading, setIsLoading] = useState<any>({});

  useEffect(() => {
    dispatch(getJobsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jobStore.isLoading) return;
    let _id = parseInt(id);
    let job = jobStore.data.find(x => x.id === _id);
    if (!job) return;
    setModel(job);

    JobsService.getHistory(_id).subscribe(x => setHistory(x.data))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobStore]);

  function canChangeStatus(job: JobInterface) {
    if (!userAuth.user) return false;

    switch (job.status!.id) {
      case JobStatusEnum.CREATED: return job.creator_organization!.id === userAuth.user.organization!.id;
      case JobStatusEnum.RECEIVED: return false;
      case JobStatusEnum.ACCEPTED: return job.creator_organization!.id !== userAuth.user.organization!.id;
      case JobStatusEnum.PROCESSING: return job.creator_organization!.id !== userAuth.user.organization!.id;
      case JobStatusEnum.PRINTED: return false;
      case JobStatusEnum.SENT: return false;
      case JobStatusEnum.CANCELED: return false;
    }
  }

  function download(file: JobFileInterface) {
    if(file.dms_uuid) {
      setIsLoading({ ...isLoading, [file.id!]: true });
      DmsService.getTempUrl(file.dms_uuid).subscribe(x => {
        setIsLoading({ ...isLoading, [file.id!]: false });
        window.open(x.data.url, '_blank')!.focus();
      });
    }
  }

  if (!model) return (<div></div>);

  return (
    <div className="job w100-h100">
      <Descriptions bordered={true} column={1} labelStyle={{ backgroundColor: '#D5D5D5' }} contentStyle={{ backgroundColor: '#FFF' }}>
        <Descriptions.Item label="Naziv">{model.name}</Descriptions.Item>
        <Descriptions.Item label="Opis">{model.description}</Descriptions.Item>
        <Descriptions.Item label="Printer">{model.printer ? `${model.printer_organization!.name} - ${model.printer.name}` : `${model.printer_organization!.name}`}</Descriptions.Item>
        <Descriptions.Item label="Datoteke">
          <ul>
            {model.files.map(x => (
              <li key={x.id}>
                <Button onClick={() => download(x)} loading={isLoading[x.id!]} type="link" icon={<DownloadOutlined />} >
                  {x.name}
                </Button>
              </li>
            ))}
          </ul>
        </Descriptions.Item>
        <Descriptions.Item label="Status">{i18n.translate('enums.jobStatus.' + model.status?.id)}</Descriptions.Item>
        <Descriptions.Item label="Povijest">
        <Timeline mode={'left'}>
          {history.map(x => (<Timeline.Item key={x.id} label={x.created_at}>{i18n.translate('enums.jobStatus.' + x.status?.id)} - {x.creator ? x.creator.name : 'API'}</Timeline.Item>))}
        </Timeline>
        </Descriptions.Item>
      </Descriptions>

    {canChangeStatus(model) && <div className="center-center mt16 mb16">
        <Button onClick={() => { NavigationService.navigate(AppRoutes.JOBS.fullPath + '/edit/' + model.id); }} type="link" icon={<EditOutlined />} >
          {i18n.translate('common.btns.edit')}
        </Button>
      </div>}
    </div>
  );
}

export default JobViewPageComponent;
