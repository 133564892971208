import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Form, Input, Button, Select, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { NavigationService, JobInterface, StoreStateInterface, JobsService, JobFileInterface, JobStatusEnum } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { getJobsAction, JobConstants, JobStateInterface } from '../../../common/redux/jobs';

const { Option, OptGroup } = Select;

interface ParamTypes {
  id: string;
}

function JobEditPageComponent() {
  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const jobStore: JobStateInterface = useSelector((state: StoreStateInterface) => state.jobs) as JobStateInterface;

  const [form] = Form.useForm();
  const [files, setFiles] = useState<any>([]);
  const [filesToRemove, setFilesToRemove] = useState<any>([]);
  const [printers, setPrinters] = useState<Array<{ groupName: string; options: Array<{ key: string; value: string }> }>>([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState<JobInterface | undefined>();

  useEffect(() => {
    dispatch(getJobsAction());
    loadPrinters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id === 'new') return;
    if (jobStore.isLoading) return;
    let _id = parseInt(id);
    let job = jobStore.data.find(x => x.id === _id);
    if (!job) return;

    if (!canChangeStatus(job)) {
      NavigationService.navigate(AppRoutes.JOBS.fullPath);
      return;
    }

    modellToForm(job);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobStore]);

  function canChangeStatus(job: JobInterface) {
    if (!userAuth.user) return false;

    switch (job.status!.id) {
      case JobStatusEnum.CREATED: return job.creator_organization!.id === userAuth.user.organization!.id;
      case JobStatusEnum.RECEIVED: return false;
      case JobStatusEnum.ACCEPTED: return job.creator_organization!.id !== userAuth.user.organization!.id;
      case JobStatusEnum.PROCESSING: return job.creator_organization!.id !== userAuth.user.organization!.id;
      case JobStatusEnum.PRINTED: return false;
      case JobStatusEnum.SENT: return false;
      case JobStatusEnum.CANCELED: return false;
    }
  }

  function loadPrinters() {
    let p: Array<{ groupName: string; options: Array<{ key: string; value: string }> }> = [];
    if (userAuth.user?.organization?.printers && userAuth.user?.organization?.printers?.length > 0) {
      p.push({
        groupName: userAuth.user.organization.name,
        options: [{ key: `${userAuth.user.organization.id}`, value: '*BILOKOJI*' }, ...userAuth.user?.organization?.printers.map(x => ({ key: `${userAuth.user!.organization!.id}-${x.id}`, value: x.name }))]
      });
    }

    userAuth.user?.organization?.partners?.filter(x => x.is_active)?.forEach(partner => {
      p.push({
        groupName: partner.client.name,
        options: [{ key: `${partner.client.id}`, value: '*BILOKOJI*' }, ...partner.client.printers!.map(x => ({ key: `${partner.client.id}-${x.id}`, value: x.name }))]
      });
    });

    setPrinters(p);
  }

  const onFinish = (formValues: any) => {
    let job: JobInterface = formToModel(formValues);
    if (job.files.length === 0) {
      alert("Nedostaju datoteke!"); // TODO use ant message
      return;
    }
    setIsLoading(true);
    JobsService.post(job).subscribe((response) => {
      dispatch({ type: JobConstants.SET, payload: response.data });
      NavigationService.navigate(AppRoutes.JOBS.fullPath);
    });
  };

  function modellToForm(job: JobInterface): void {
    setFiles(job.files);
    setModel(job);
    form.setFieldsValue({
      name: job.name,
      description: job.description,
      printer: job.printer ? `${job.printer_organization!.id}-${job.printer.id}` : `${job.printer_organization!.id}`
    });
  }

  function formToModel(formValues: any): JobInterface {
    let printerParts = formValues.printer.split('-');

    let f: JobFileInterface = files.map((x: any) => ({
      id: x.id,
      name: x.name,
      type: x.name.split('.')[1],
      dms_uuid: x.dms_uuid ? x.dms_uuid : x.response[0].uuid
    }));

    let fr: JobFileInterface = filesToRemove.map((x: any) => ({
      id: x.id,
      name: x.name,
      type: x.name.split('.')[1],
      dms_uuid: x.dms_uuid ? x.dms_uuid : x.response[0].uuid
    }));

    let job: JobInterface = {
      name: formValues.name,
      description: formValues.description,
      printer_organization_id: printerParts[0],
      printer_id: printerParts.length > 1 ? printerParts[1] : null,
      files: f,
      filesToRemove: fr
    } as any;

    if (model) {
      job.id = model.id;
    } else {
      job = {
        ...job,
        creator_organization_id: userAuth.user?.organization?.id!,
      }
    }

    return job;
  }

  const props: UploadProps = {
    accept: '.stl,.zip',
    name: 'files',
    action: 'https://dms.onlyoneif.com/api/v1/temp-url/0BED770246F671499E8A937525B94A53F98AB80D5F8ACD6BFD3F50B53FC67D61',
    fileList: files,
    onRemove(file) {
      console.log("=== remove");
      console.log(file);
    },
    onChange(info) {
      console.log(info);
      setFiles(info.fileList);
      switch (info.file.status) {
        case ('uploading'): break;
        case ('done'): break;
        case ('removed'):
          setFilesToRemove([...filesToRemove, info.file]);
          break;
        case ('error'): break;
      }
    },
  };

  return (
    <div className="job w100-h100">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        autoComplete="off"
      >

        <Form.Item label="Naziv" name="name" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Opis" name="description">
          <TextArea />
        </Form.Item>

        <Form.Item label="Printer" name="printer" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Select placeholder="Select a option">
            { printers.map(x => (
              <OptGroup key={x.groupName} label={x.groupName}>
                { x.options.map(o => (<Option key={x.groupName+o.key} value={o.key}>{o.value}</Option>)) }
              </OptGroup>
            )) }
          </Select>
        </Form.Item>

        <Form.Item label="Upload" valuePropName="fileList">
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}> Spremi </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default JobEditPageComponent;
