import { i18n } from "../services";

export enum UserRoleEnum {
    SYSTEM = 1,
    API = 2,
    SUPERADMIN = 3,
    ADMIN = 4,
    USER = 5,
}

export const UserRoleEnumList = [UserRoleEnum.ADMIN, UserRoleEnum.USER];

export const UserRoleEnumTranslate = (x: UserRoleEnum) => i18n.translate(`enums.userRole.${x}`);