import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Dropdown, Menu, MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { i18n, JobInterface, JobsService, JobStatusActionEnumTranslate, JobStatusEnum, JobStatusEnumTranslate, NavigationService, StoreStateInterface } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import { getJobsAction, JobStateInterface } from '../../../common/redux/jobs';

function JobsPageComponent() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const jobStore: JobStateInterface = useSelector((state: StoreStateInterface) => state.jobs) as JobStateInterface;

  useEffect(() => {
    dispatch(getJobsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function canChangeStatus(job: JobInterface, newStatus: JobStatusEnum) {
    if (!userAuth.user) return false;

    switch (newStatus) {
      case JobStatusEnum.RECEIVED: return job.creator_organization!.id === userAuth.user.organization!.id;
      case JobStatusEnum.ACCEPTED: return job.creator_organization!.id !== userAuth.user.organization!.id;
      case JobStatusEnum.PROCESSING: return job.creator_organization!.id !== userAuth.user.organization!.id;
      case JobStatusEnum.PRINTED: return job.creator_organization!.id !== userAuth.user.organization!.id;
      case JobStatusEnum.SENT: return job.creator_organization!.id !== userAuth.user.organization!.id;
      case JobStatusEnum.CANCELED: return job.creator_organization!.id !== userAuth.user.organization!.id;
    }
  }
  
  const columns = [
    {
      title: i18n.translate('job.table.id'),
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => <a onClick={() => { NavigationService.navigate(AppRoutes.JOBS.fullPath + '/view/' + text); }}>{text}</a>,
    },
    {
      title: i18n.translate('job.table.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i18n.translate('job.table.description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: i18n.translate('job.table.client'),
      render: (job: JobInterface) => job.creator_organization?.name
    },
    {
      title: i18n.translate('job.table.partner'),
      render: (job: JobInterface) => job.printer_organization?.name
    },
    {
      title: i18n.translate('job.table.printer'),
      render: (job: JobInterface) => job.printer?.name
    },
    {
      title: i18n.translate('job.table.status'),
      render: (job: JobInterface) => (<div className={`status status__${job.status?.id!}`}>{JobStatusEnumTranslate(job.status?.id!)}</div>)
    },
    {
      title: '',
      width: 100,
      render: (job: JobInterface) => {
        if (job.status?.id === JobStatusEnum.CANCELED || job.status?.id === JobStatusEnum.SENT)return null;
        if (!canChangeStatus(job, job.status?.id! + 1)) return null;
        return (
        <Dropdown.Button onClick={() => updateStatus(job.id!, job.status?.id! + 1)} 
                        type="primary" 
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => updateStatus(job.id!, JobStatusEnum.CREATED)}>{"Vrati u pripremu"}</Menu.Item>
                            <Menu.Item onClick={() => updateStatus(job.id!, JobStatusEnum.CANCELED)}>{JobStatusActionEnumTranslate(JobStatusEnum.CANCELED)}</Menu.Item>
                          </Menu>
                        }>
            {JobStatusActionEnumTranslate(job.status?.id! + 1)}
        </Dropdown.Button>
      );
        
      }
    },
  ];

  function updateStatus(id: number, status: JobStatusEnum) {
    JobsService.statusUpdate(id, status).subscribe(() => {
      dispatch(getJobsAction());
    });
  }

  return (
    <div className="jobs w100-h100">
      <Button onClick={() => { NavigationService.navigate(AppRoutes.JOBS.fullPath + '/edit/new'); }} type="primary" shape="round" className='mb8' icon={<PlusOutlined />}>
        {i18n.translate('common.btns.add')}
      </Button>

      {/* MAIN TABLE */}
      <Table loading={jobStore.isLoading} columns={columns} dataSource={ jobStore.data } rowKey={(row) => row.id!} pagination={{ hideOnSinglePage: true }} />
    </div>
  );
}

export default JobsPageComponent;
