import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button } from 'antd';
import { PlusOutlined, DeleteRowOutlined } from '@ant-design/icons';
import { i18n, NavigationService, OrganizationInterface, StoreStateInterface } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import { getOrganizationsAction, OrganizationStateInterface } from '../../../common/redux/organizations';

function OrganizationsPageComponent() {
  const dispatch = useDispatch();
  const organizationStore: OrganizationStateInterface = useSelector((state: StoreStateInterface) => state.organizations) as OrganizationStateInterface;
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    dispatch(getOrganizationsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: i18n.translate('organization.table.id'),
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => <a onClick={() => { NavigationService.navigate(AppRoutes.ORGANIZATIONS.fullPath + '/' + text); }}>{text}</a>,
    },
    {
      title: i18n.translate('organization.table.name'),
      render: (organization: OrganizationInterface) => `${organization.name} ${organization.is_active ? '' : '['+i18n.translate('organization.msgs.unactive')+']'}`
    },
    {
      title: i18n.translate('organization.table.uuid'),
      dataIndex: 'uuid',
      key: 'uuid'
    },
    {
      title: i18n.translate('organization.table.print_offer'),
      render: (organization: OrganizationInterface) => organization.print_offer ? i18n.translate('common.yesNo.yes') : i18n.translate('common.yesNo.no')
    },
    {
      title: i18n.translate('organization.table.print_request'),
      render: (organization: OrganizationInterface) => organization.print_request ? i18n.translate('common.yesNo.yes') : i18n.translate('common.yesNo.no')
    },
    {
      title: i18n.translate('organization.table.printers'),
      render: (organization: OrganizationInterface) => organization.printers!.length
    },
    {
      title: i18n.translate('organization.table.clients'),
      render: (organization: OrganizationInterface) => organization.clients!.filter(x => x.is_active).length
    },
    {
      title: i18n.translate('organization.table.partners'),
      render: (organization: OrganizationInterface) => organization.partners!.filter(x => x.is_active).length
    },
  ];

  return (
    <div className="organization w100-h100">
      <div className="flex-row space-between">
        <Button onClick={() => { NavigationService.navigate(AppRoutes.ORGANIZATIONS.fullPath + '/new'); }} type="primary" shape="round" className='mb8' icon={<PlusOutlined />}>
          {i18n.translate('common.btns.add')}
        </Button>
        <Button onClick={() => setShowInactive(!showInactive)} type={showInactive ? 'primary' : 'default'} shape="round" icon={<DeleteRowOutlined />} />
      </div>

      {/* MAIN TABLE */}
      <Table loading={organizationStore.isLoading} columns={columns} dataSource={ organizationStore.data?.filter(x => (showInactive ? !x.is_active : x.is_active)) } rowKey={(row) => row.id!} pagination={{ hideOnSinglePage: true }} />
    </div>
  );
}

export default OrganizationsPageComponent;
