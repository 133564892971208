import React, { useEffect, useState } from 'react';
import { Table, Button, Drawer } from 'antd';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { i18n, notificationCountAction, NotificationInterface, NotificationService } from '../../../common';
import { useDispatch } from 'react-redux';

function NotificationsPageComponent() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Array<NotificationInterface>>([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function loadData() {
    setIsLoading(true);
    NotificationService.getAll().subscribe(u => {
      setData(u.data);
      setIsLoading(false);
    });
    dispatch(notificationCountAction());
  }

  const columns = [
    {
      title: i18n.translate('organization.table.id'),
      render: (n: NotificationInterface) => {
        let payload: any = {};
        try {
          payload = JSON.parse(n.payload);
          switch (n.message_name) {
            case 'job.statusChanged':
              payload.job_status_id = i18n.translate('enums.jobStatus.' + payload.job_status_id);
              break;
          }
        } catch (error) {}
        return i18n.translate('notifications.' + n.message_name, payload);
      },
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (n: NotificationInterface) => ( <Button onClick={() => NotificationService.read([n.id!]).subscribe( () => loadData() )  } type="primary"> <EyeOutlined /> </Button> ),
    },
  ];

  return (
    <div className="organization w100-h100">
        <Button onClick={() => NotificationService.readAll().subscribe( () => loadData() ) } type="primary" shape="round" className='mb8' icon={<PlusOutlined />}>
          Pročitaj sve
        </Button>

      {/* MAIN TABLE */}
      <Table loading={isLoading} columns={columns} dataSource={ data } rowKey={(row) => row.id!} pagination={{ hideOnSinglePage: true }} />
    </div>
  );
}

export default NotificationsPageComponent;
