import { Form, Input, Select, Button, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { UserService } from '../../api';
import { UserRoleEnumList, UserRoleEnumTranslate } from '../../enums';
import { UserInterface } from '../../interfaces';

const { Option } = Select;

export default function UserFormComponent({ user, organizationId, onClose }: { user: UserInterface; organizationId: number|undefined; onClose: (replace: boolean, user: UserInterface) => void }) {
  
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState<UserInterface | undefined>();

  useEffect(() => {
    if (!user || !user.id) return;
    modellToForm(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onFinish = (formValues: any) => {
    setIsLoading(true);
    let u: UserInterface = formToModel(formValues);
    UserService.post(u).subscribe((response) => {
      onClose(u.id === response.data.id, response.data);
    });
  };

  function modellToForm(data: UserInterface): void {
    setModel(data);
    form.setFieldsValue({
      name: data.name,
      email: data.email,
      role_id: data.role_id,
      is_active: data.is_active,
    });
  }

  function formToModel(formValues: any): UserInterface {
    let data: UserInterface = {
      name: formValues.name,
      email: formValues.email,
      role_id: formValues.role_id,
      organization_id: organizationId,
      is_active: formValues.is_active,
    };

    if (model) {
      data.id = model.id;
    } else {
      data.password = formValues.password;
    }

    return data;
  }

  return (
    <div className="printer w100-h100">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ is_active: 1 }}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        
        <Form.Item label="Ime" name="name" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Input />
        </Form.Item>
        
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Input />
        </Form.Item>

        {!model && (
          <Form.Item label="Lozinka" name="password" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
            <Input />
          </Form.Item>
        )}
        
        <Form.Item label="Uloga" name="role_id" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Select placeholder="Select a option">
            { UserRoleEnumList.map(x => (<Option key={x} value={x}>{UserRoleEnumTranslate(x)}</Option>)) }
          </Select>
        </Form.Item>

        <Form.Item label="Aktivna" name="is_active" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}> Spremi </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
