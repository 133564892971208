import { Dispatch } from 'redux';
import { BaseResponseInterface, PrinterInterface } from '../../interfaces';
import { PrinterService } from '../../api/api.service';
import { PrinterConstants } from './printer.constants';

export function getPrintersAction() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PrinterConstants.LOADING,
      payload: true,
    });
    return PrinterService.getAll().subscribe(
      (response: BaseResponseInterface<PrinterInterface>) => {
        dispatch({
          type: PrinterConstants.SET,
          payload: response.data,
        });
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}
