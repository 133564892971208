import React, { useState } from 'react';
import { Button, Descriptions } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';

import { i18n, OrganizationService } from '../../../common';

function ApiOrganizationPageComponent() {

  const [server, setServer] = useState('https://api.printer.onlyoneif.com');
  const [apiKey, setApiKey] = useState('');

  function getDocumentation() {
    window.open(window.location.origin + '/static/docs/3D-Printers-organization-api.postman_collection.json', '_blank');
  }

  function getApiKey() {
    OrganizationService.getApiKey().subscribe(x => setApiKey(x.data))
  }

  function renderApiKey() {
    if (apiKey) return apiKey;
    return (
      <Button onClick={getApiKey} type="link" icon={<EyeOutlined />} >
        {i18n.translate('apiOrganization.section1.fetchBtn')}
      </Button>
    );
  }

  return (
    <div className="w100-h100">
      <Descriptions title={i18n.translate('apiOrganization.section1.title')}>
        <Descriptions.Item label={i18n.translate('apiOrganization.section1.server')}>
          {server}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.translate('apiOrganization.section1.key')}>
          {renderApiKey()}
        </Descriptions.Item>
      </Descriptions>

      <hr />

      <Descriptions title={i18n.translate('apiOrganization.section2.title')}>
        <Descriptions.Item label={i18n.translate('apiOrganization.section2.download')}>
          <Button onClick={getDocumentation} type="link" icon={<DownloadOutlined />} >
            {i18n.translate('apiOrganization.section2.downloadBtn')}
          </Button>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default ApiOrganizationPageComponent;
