import { Form, Input, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { OrganizationService } from '../../api';

export default function AddPartnerFormComponent({ isAddPartner, organizationId, onClose }: { isAddPartner: boolean, organizationId: number, onClose: () => void }) {
  
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (formValues: any) => {
    setIsLoading(true);
    let fn = isAddPartner ? OrganizationService.postAddPartner : OrganizationService.postAddClient;
    fn(organizationId, formValues.partner_uuid).subscribe((response) => {
      
      onClose();
    });
  };

  return (
    <div className="printer w100-h100">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ is_active: 1 }}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item label="Identifikator organizacije" name="partner_uuid" rules={[{ required: true, message: 'Ovo polje je obavezno' }]}>
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}> Dodaj </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
