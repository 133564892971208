export const ROOT_PATH_APP = 'app';

export const AppRoutes = {
  DASHBOARD:          { path: 'dashboard',        fullPath: `/${ROOT_PATH_APP}/dashboard` },
  JOBS:               { path: 'jobs',             fullPath: `/${ROOT_PATH_APP}/jobs` },
  PRINTER:            { path: 'printers',         fullPath: `/${ROOT_PATH_APP}/printers` },
  ORGANIZATIONS:      { path: 'organizations',    fullPath: `/${ROOT_PATH_APP}/organizations` },
  PARTNERS:           { path: 'partners',         fullPath: `/${ROOT_PATH_APP}/partners` },
  CLIENTS:            { path: 'clients',          fullPath: `/${ROOT_PATH_APP}/clients` },
  USERS:              { path: 'users',            fullPath: `/${ROOT_PATH_APP}/users` },
  SOFTWARE_RELEASE:   { path: 'software-releases',  fullPath: `/${ROOT_PATH_APP}/software-releases` },
  NOTIFICATIONS:      { path: 'notifications',      fullPath: `/${ROOT_PATH_APP}/notifications` },
  API_ORGANIZATION:   { path: 'api-organization',   fullPath: `/${ROOT_PATH_APP}/api-organization` },
  API_PRINTER:        { path: 'api-printer',        fullPath: `/${ROOT_PATH_APP}/api-printer` },
};
