import { Dispatch } from 'redux';
import { JobsService } from '../../api';
import { BaseResponseInterface, JobInterface } from '../../interfaces';
import { JobConstants } from './jobs.constants';

export function getJobsAction() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: JobConstants.LOADING,
      payload: true,
    });
    return JobsService.getAll().subscribe(
      (response: BaseResponseInterface<Array<JobInterface>>) => {
        dispatch({
          type: JobConstants.SET,
          payload: response.data,
        });
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}
