import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Radio, Drawer, Space, Button, notification } from 'antd';
import moment from 'moment';
import { ContainerOutlined, BranchesOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { i18n, getPrintersAction, loadSoftwareReleaseAction, NavigationService, PrinterEventInterface, PrinterEventService, PrinterService, PrinterStateInterface, SoftwareReleaseInterface, SoftwareReleaseStateInterface, StoreStateInterface, PrinterConstants, UserRoleEnum } from '../../../common';
import { PrinterInterface } from '../../../common/interfaces/common/printer.interface';
import { AppRoutes } from '../_router/app.routes';

function PrintersPageComponent() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const printerStore: PrinterStateInterface = useSelector((state: StoreStateInterface) => state.printers) as PrinterStateInterface;
  const softwareReleaseStore: SoftwareReleaseStateInterface = useSelector((state: StoreStateInterface) => state.softwareReleases) as SoftwareReleaseStateInterface;
  const [eventDrawerData, setEventDrwerData] = useState<Array<PrinterEventInterface>>([]);
  const [versionDrawerData, setVersionDrawerData] = useState<PrinterInterface | null>(null);
  const [active, setActive] = useState<'active' | 'inactive'>('active');

  useEffect(() => {
    dispatch(getPrintersAction());
    dispatch(loadSoftwareReleaseAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: i18n.translate('printer.table.id'),
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => {
        if (userAuth.user!.role_id === UserRoleEnum.SUPERADMIN) {
          return (<a onClick={() => { NavigationService.navigate(AppRoutes.PRINTER.fullPath + '/' + text); }}>{text}</a>);
        }
        return text;
      }
    },
    {
      title: i18n.translate('printer.table.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i18n.translate('printer.table.organization'),
      render: (printer: PrinterInterface) => printer.organization?.name
    },
    // {
    //   title: i18n.translate('printer.table.location'),
    //   dataIndex: 'location',
    //   key: 'location'
    // },
    // {
    //   title: i18n.translate('printer.table.serial_number'),
    //   dataIndex: 'serial_number',
    //   key: 'serial_number'
    // },
    {
      title: i18n.translate('printer.table.current_version'),
      render: (printer: PrinterInterface) => printer.current_version!.version
    },
    {
      title: i18n.translate('printer.table.avalible_version'),
      render: (printer: PrinterInterface) => printer.available_version!.version
    },
    {
      title: i18n.translate('printer.table.last_ping'),
      render: (printer: PrinterInterface) => (
        <>
          {printer.last_ping ? moment(printer.last_ping).format('DD.MM.YYYY. HH:mm ') : '-'}
        </>
      ),
    },
    {
      title: i18n.translate('printer.table.last_print'),
      render: (printer: PrinterInterface) => (
        <>
          {printer.last_print ? moment(printer.last_print).format('DD.MM.YYYY. HH:mm ') : '-'}
        </>
      ),
    },
    {
      title: i18n.translate('printer.table.status'),
      render: (printer: PrinterInterface) => {
        let isOffline = true;
        if (printer.last_ping) {
          isOffline = moment().valueOf() >= moment(printer.last_ping).add(15, 'minutes').valueOf()
        }
        return (
          <>
            {!isOffline && (<Tag color="green">ONLINE</Tag>)}
            {isOffline && (<Tag color="red">OFFLINE</Tag>)}
          </>
        )
      },
    },
    {
      title: i18n.translate('printer.table.status'),
      render: (printer: PrinterInterface) => printer.status!.name
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (printer: PrinterInterface) => (
        <Space size="middle">
          {userAuth.user!.role_id === UserRoleEnum.SUPERADMIN && (<BranchesOutlined onClick={() => changeVersionBtnClick(printer)} />)}
          <ContainerOutlined onClick={() => onOpenEventDetailsClick(printer)} />
        </Space>
      ),
    },
  ];
  
  const columnsTableVersions = [
    {
      title: i18n.translate('printer.eventsTable.date'),
      render: (sw: SoftwareReleaseInterface) => moment(sw.date).format('DD.MM.YYYY. HH:mm '),
    },
    {
      title: i18n.translate('printer.eventsTable.name'),
      render: (sw: SoftwareReleaseInterface) => sw.version,
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (sw: PrinterEventInterface) => (
        <>
          {sw.id !== versionDrawerData?.available_version_id && (<Button onClick={() => onSetVersionForPrinter(sw)} type="primary"> <UploadOutlined /> </Button>)}
        </>
      ),
    },
  ];

  const columnsTableEvent = [
    {
      title: i18n.translate('printer.eventsTable.date'),
      render: (event: PrinterEventInterface) => moment(event.created_at).format('DD.MM.YYYY. HH:mm '),
    },
    {
      title: i18n.translate('printer.eventsTable.name'),
      render: (event: PrinterEventInterface) => event.event.name,
    },
  ];

  function onOpenEventDetailsClick(printer: PrinterInterface) {
    PrinterEventService.getPrinterEvents(printer.id!).subscribe((data) => {
      setEventDrwerData(data.data);
      if (data.data.length === 0) {
        notification['warning']({ message: i18n.translate('printer.msgs.noEvents'), duration: 2 });
      }
    });
  }

  function changeVersionBtnClick(printer: PrinterInterface) {
    setVersionDrawerData(printer);
  }

  function onSetVersionForPrinter(sw: PrinterEventInterface) {
    let printer: PrinterInterface = versionDrawerData!;
    printer.available_version_id = sw.id;
    PrinterService.post(printer).subscribe((response) => {
      dispatch({ type: PrinterConstants.SET, payload: response.data });
      setVersionDrawerData(null)
    });
  }

  function getData() {
    return printerStore.data.filter(x => active === 'active' ? x.is_active : !x.is_active);
  }

  // === RENDER ===

  return (
    <div className="printer w100-h100">

      <div className="flex-row space-between mb8">
        {userAuth.user?.role_id === UserRoleEnum.SUPERADMIN &&  (<Button onClick={() => { NavigationService.navigate(AppRoutes.PRINTER.fullPath + '/new'); }} type="primary" shape="round" className='mb8' icon={<PlusOutlined />}>
            {i18n.translate('common.btns.add')}
        </Button>)}

        <Radio.Group value={active} onChange={e => setActive(e.target.value)}>
          <Radio.Button value="active">{i18n.translate('common.activeInactive.active')}</Radio.Button>
          <Radio.Button value="false">{i18n.translate('common.activeInactive.inactive')}</Radio.Button>
        </Radio.Group>
      </div>

      {/* MAIN TABLE */}
      <Table loading={printerStore.isLoading} columns={columns} dataSource={getData()} rowKey={(row) => row.id!} pagination={{ hideOnSinglePage: true }} />
      
      {/* VERSION DRAWER */}
      <Drawer title={i18n.translate('printer.drawer.versions')} placement="right" width={'50%'} onClose={() => setVersionDrawerData(null)} visible={versionDrawerData !== null}>
        <Table columns={columnsTableVersions} dataSource={softwareReleaseStore.data.filter(x => x.is_active)} rowKey={(row: SoftwareReleaseInterface) => row.id} pagination={{ hideOnSinglePage: true }} />
      </Drawer>

      {/* EVENTS DRAWER */}
      <Drawer title={i18n.translate('printer.drawer.events')} placement="right" width={'50%'} onClose={() => setEventDrwerData([])} visible={eventDrawerData.length > 0}>
        <Table columns={columnsTableEvent} dataSource={eventDrawerData} rowKey={(row: PrinterEventInterface) => row.id} pagination={{ hideOnSinglePage: true }} />
        <div className='events-leggend'> 
          Legenda: <br />
          [1]	OS_START <br/> 
          [2]	OS_STOP <br/>
          [3]	APP_START <br/>
          [4]	PRINT_START <br/>
          [5]	PRINT_END <br/>
          [6]	UPDATE_RECIEVED <br/>
          [7]	UPDATE_STARTED <br/>
          [8]	PING <br/>
        </div>
      </Drawer>
    </div>
  );
}

export default PrintersPageComponent;
