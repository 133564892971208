import React, { useEffect, useState } from 'react';
import { Table, Button, Drawer, Popconfirm } from 'antd';
import { PlusOutlined, DeleteRowOutlined, DeleteOutlined } from '@ant-design/icons';
import { AuthConstants, i18n, NavigationService, OrganizationInterface, OrganizationService, UserService } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import AddPartnerFormComponent from '../../../common/components/addPartnerForm/addPartnerForm.component';
import { useDispatch } from 'react-redux';

function PartnersPageComponent() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [organizationId, setOrganizationId] = useState<number>(-1);
  const [data, setData] = useState<Array<OrganizationInterface>>([]);
  const [showInactive, setShowInactive] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInactive]);

  function loadData() {
    setIsLoading(true);
    // let fn = isAddPartner ? 
    UserService.getCurrentUser().subscribe(u => {
      
      dispatch({
        type: AuthConstants.SET_USER,
        payload: u.data,
      });

      setOrganizationId(u.data.organization!.id!);
      let d: any = u.data.organization![isAddPartner() ? 'partners' : 'clients']!;
      setData(d.filter((x: any) => (showInactive ? !x.is_active : x.is_active)).map((x: any) => x[isAddPartner() ? 'client' : 'partner']));
      setIsLoading(false);
    });
  }

  function isAddPartner() {
    return window.location.pathname.endsWith(AppRoutes.PARTNERS.path);
  }

  const columns = [
    {
      title: i18n.translate('organization.table.id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i18n.translate('organization.table.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (o: OrganizationInterface) => (
        <>
          {!showInactive && (
            <Popconfirm placement="left" title={"Da li ste sigurni da želite ukloniti ovaj zapis?"} onConfirm={() => removeItem(o.id!)} okText="DA" cancelText="NE">
              <Button type="primary"> <DeleteOutlined /> </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  function removeItem(partnerId: number) {
    setIsLoading(true);
    let fn = isAddPartner() ? OrganizationService.removePartner : OrganizationService.removeClient;
    fn(organizationId, partnerId).subscribe(u => {
      loadData();
    });
  }

  return (
    <div className="organization w100-h100">
      <div className="flex-row space-between">
        <Button onClick={() => { setShowDrawer(true) }} type="primary" shape="round" className='mb8' icon={<PlusOutlined />}>
          {i18n.translate('common.btns.add')}
        </Button>
        <Button onClick={() => setShowInactive(!showInactive)} type={showInactive ? 'primary' : 'default'} shape="round" icon={<DeleteRowOutlined />} />
      </div>

      {/* MAIN TABLE */}
      <Table loading={isLoading} columns={columns} dataSource={ data } rowKey={(row) => row.id!} pagination={{ hideOnSinglePage: true }} />
      
      {/* DRAWER */}
      <Drawer title="Printer events" placement="right" width={'50%'} onClose={() => setShowDrawer(false)} visible={showDrawer}>
        { showDrawer && (<AddPartnerFormComponent isAddPartner={isAddPartner()} organizationId={organizationId} onClose={() => { loadData(); setShowDrawer(false); }}/>) }
      </Drawer>
    </div>
  );
}

export default PartnersPageComponent;
