import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';

import { i18n, NavigationService } from '../../services';
import { setLang } from '../../services/translate.service';
import { AuthConstants, notificationCountAction } from '../../redux/auth';
import { StoreStateInterface } from '../../redux/store';
import { AppRoutes } from '../../../features/app';

export default function HeaderComponent() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const nextLang = userAuth.lang === 'en' ? 'hr' : 'en';

  useEffect(() => {
    if (!userAuth || !userAuth.user) return;
    dispatch(notificationCountAction());
  }, []);

  function onLangChange() {
    setLang(nextLang);
    dispatch({ type: AuthConstants.SET_LANG, payload: nextLang });
  }

  function onNotificationClick() {
    NavigationService.navigate(AppRoutes.NOTIFICATIONS.fullPath);
  }

  return (
    <header className="header">
      <h1>{i18n.translate('header.title')}</h1>
      <div>
        { userAuth.notifications > 0 && <Badge count={userAuth.notifications} offset={[8, 2]}>
          <Button onClick={onNotificationClick} type="link"><BellOutlined /></Button>
        </Badge>}
        <Button style={{marginLeft: 24}} onClick={onLangChange}>{nextLang.toUpperCase()}</Button>
      </div>
    </header>
  );
}
