
import { i18n } from "../services";

export enum PrinterEventEnum {
    OS_START = 1,
    OS_STOP = 2,
    APP_START = 3,
    PRINT_START = 4,
    PRINT_END = 5,
    UPDATE_RECIEVED = 6,
    UPDATE_STARTED = 7,
    PING = 8,
}

export const PrinterEventEnumList = [
    PrinterEventEnum.OS_START,
    PrinterEventEnum.OS_STOP,
    PrinterEventEnum.APP_START,
    PrinterEventEnum.PRINT_START,
    PrinterEventEnum.PRINT_END,
    PrinterEventEnum.UPDATE_RECIEVED,
    PrinterEventEnum.UPDATE_STARTED,
    PrinterEventEnum.PING
];

export const PrinterEventEnumTranslate = (x: PrinterEventEnum) => i18n.translate(`enums.printerEvent.${x}`);