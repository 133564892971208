import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Select } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';

import { getPrintersAction, i18n, PrinterService, PrinterStateInterface, StoreStateInterface } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

function ApiPrinterPageComponent() {

  const dispatch = useDispatch();
  const [printer, setPrinter] = useState<number>();
  const [apiKey, setApiKey] = useState('');
  const printerStore: PrinterStateInterface = useSelector((state: StoreStateInterface) => state.printers) as PrinterStateInterface;

  useEffect(() => {
    dispatch(getPrintersAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getIotPostman() {
    window.open(window.location.origin + '/static/docs/3D-Printers-printer-api.postman_collection.json', '_blank');
  }

  function getMtPostman() {
    window.open(window.location.origin + '/static/docs/3D-printers-mt.postman_collection.json', '_blank');
  }

  function getApiKey() {
    if (!printer) return;
    PrinterService.getApiKey(printer).subscribe(x => setApiKey(x.data))
  }

  function renderApiKey() {
    if (!printer) return '';
    if (apiKey) return apiKey;
    return (
      <Button onClick={getApiKey} type="link" icon={<EyeOutlined />} >
        {i18n.translate('apiPrinter.section1.fetchBtn')}
      </Button>
    );
  }

  const handleChange = (value: number) => {
    setApiKey('');
    setPrinter(value);
  };

  return (
    <div className="w100-h100">
      <Descriptions title={i18n.translate('apiPrinter.section1.title')}>
        <Descriptions.Item label={i18n.translate('apiPrinter.section1.printer')}>
          <Select style={{ width: 250 }}  onChange={handleChange}>
            {printerStore.data.filter(x => x.is_active).map((x) => (<Option key={x.id} value={x.id!}>{x.name} | {x.location}</Option>))}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={i18n.translate('apiPrinter.section1.key')}>
          {renderApiKey()}
        </Descriptions.Item>
      </Descriptions>

      <hr />

      <Descriptions title={i18n.translate('apiPrinter.section2.title')}>
        <Descriptions.Item label={i18n.translate('apiPrinter.section2.downloadIot')}>
          <Button onClick={getIotPostman} type="link" icon={<DownloadOutlined />} >
            {i18n.translate('apiPrinter.section2.downloadBtn')}
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label={i18n.translate('apiPrinter.section2.downloadMt')}>
          <Button onClick={getMtPostman} type="link" icon={<DownloadOutlined />} >
            {i18n.translate('apiPrinter.section2.downloadBtn')}
          </Button>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default ApiPrinterPageComponent;
