import { i18n } from "../services";

export enum PrinterStatusEnum {
    MANUFACTURING = 1,
    OPERATIONAL = 2,
    OUT_OF_USE = 3,
    DECOMMISSION = 4,
}

export const PrinterStatusEnumList = [PrinterStatusEnum.MANUFACTURING, PrinterStatusEnum.OPERATIONAL, PrinterStatusEnum.OUT_OF_USE, PrinterStatusEnum.DECOMMISSION];

export const PrinterStatusEnumTranslate = (x: PrinterStatusEnum) => i18n.translate(`enums.printerStatus.${x}`);