import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { DashboardOutlined, PrinterOutlined, UnorderedListOutlined, BellOutlined, ContainerOutlined, UserOutlined, GlobalOutlined, ContactsOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { i18n, NavigationService } from '../../services';
import { AppRoutes } from '../../../features/app/_router/app.routes';

export default function MenuComponent({ allRoutes }: any) {

    let [current, setCurrent] = useState('');
    let [routes, setRoutes] = useState<any>([]);

    useEffect(() => {
        let pahtName = window.location.pathname;
        let route = Object.values(AppRoutes).find(x => pahtName.startsWith(x.fullPath));
        if (route) setCurrent(route.path);
    }, []);

    useEffect(() => {
        setRoutes(allRoutes.filter((x: any) => x.name));
    }, [allRoutes]);

    let onMenuItemClick = (e: { key: string }) => {
        let currentKey: any = e.key;
        setCurrent(currentKey);
        let route: any = Object.values(AppRoutes).find(x => x.path === currentKey);
        NavigationService.navigate(route.fullPath);
    };

    return (
        <Menu onClick={onMenuItemClick} selectedKeys={[current]} mode="horizontal">
            {routes.map((x: any) => (
                <Menu.Item key={x.path} icon={x.icon}>
                    {i18n.translate(x.name)}
                </Menu.Item>
            ))}
        </Menu>
    );
}
