import { Dispatch } from 'redux';
import { OrganizationService } from '../../api';
import { BaseResponseInterface, OrganizationInterface } from '../../interfaces';
import { OrganizationConstants } from './organizations.constants';

export function getOrganizationsAction() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OrganizationConstants.LOADING,
      payload: true,
    });
    return OrganizationService.getAll().subscribe(
      (response: BaseResponseInterface<Array<OrganizationInterface>>) => {
        dispatch({
          type: OrganizationConstants.SET,
          payload: response.data,
        });
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}
